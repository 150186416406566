<template>
  <div>
    <label
      >{{ label }}
      <Tooltip :title="tooltipTitle" />
    </label>
    <v-select
      multiple
      :value="value"
      :options="processAreas"
      :reduce="(processArea) => processArea.id"
      :selectable="(processArea) => !value.includes(processArea.id)"
      :loading="!isReady"
      label="name"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
    >
      <template #no-options>
        {{ $t("no_process_area_available") }}
      </template>
    </v-select>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Tooltip from "@/components/tooltip.vue";

export default {
  name: "SelectMultipleProcessAreas",
  components: {
    Tooltip
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      validator(val) {
        return val.every((v) => typeof v == "number");
      }
    },
    placeholder: {
      type: String,
      default() {
        return this.$t("no_process_area_selected");
      }
    },
    label: {
      type: String,
      default() {
        return this.$t("restrict_view");
      }
    },
    tooltipTitle: {
      type: String,
      default() {
        return this.$t("titles.control_access_by_process_area");
      }
    }
  },
  computed: mapGetters("processArea", ["processAreas", "isReady"]),
  methods: mapActions("processArea", ["fetchProcessAreas"]),
  mounted() {
    if (this.isReady) {
      if ((this.value || []).length) {
        let validList = this.value.filter((i) =>
          (this.processAreas || []).some(({id}) => parseInt(id) == parseInt(i))
        );
        if (validList.length < this.value.length) {
          this.$emit("input", validList);
        }
      }
    } else {
      this.fetchProcessAreas();
    }
  }
};
</script>

<style>
.skin-dark .vs__selected {
  color: #b8c7ce;
  background-color: #2c3b41;
}

.skin-dark .vs__selected path {
  fill: #b8c7ce;
}
</style>
